/* eslint-disable @typescript-eslint/no-empty-function */

interface ErrorProperties{
  name: string,
  text: string,
  props?: any,
}

export class SignUpErrorFormatter{
  //Could organize the error types by page and what not in the future.
  errorTypes:Array<ErrorProperties> = [
    { name: 'minLength', text: 'Must be at least ', props:{ actualLength:0, requiredLength:0 } },
    { name: 'maxLength', text: 'Cannot exceed ', props:{actualLength:0, requiredLength: 0}},
    { name: 'minlength', text: 'Must be at least ', props:{ actualLength:0, requiredLength:0 } },
    { name: 'maxlength', text: 'Cannot exceed ', props:{actualLength:0, requiredLength: 0}},
    { name: 'passwordIncorrect', text:'Password must match' },
    { name: 'required', text: 'This field is required'},
    { name: 'usernameUnavailable', text: 'Username Unavailable'},
    { name: 'ccNumber', text: 'Invalid Card'},
    { name: 'expDate', text: 'Invalid Date'},
    { name: 'emailUnavailable', text: 'Email Address Unavailable'},
    { name: 'invalidEmail', text: 'Enter a valid Email'},
    { name: 'invalidAgencyUrl', text: 'Enter a valid Agency URL'},
  ]
  constructor(){}

  findError(errorName:string, props?:any){
    const findError = this.errorTypes.filter(errorType=>errorType.name===errorName)
    const foundError = findError[0]

    if(foundError===undefined){return null}

    switch(errorName){
      case 'minLength': { return foundError.text + props.minLength + ' characters'}
      case 'maxLength': { return foundError.text + props.maxLength + ' characters'}
      case 'minlength': { return foundError.text + props.minLength + ' characters'}
      case 'maxlength': { return foundError.text + props.maxLength + ' characters'}
      // If they do not have props no need to do anything but return the text
      //case 'passwordIncorrect': return foundError.text;
      //case 'required': { return foundError.text}
      default: return foundError.text;
    }



  }
}
