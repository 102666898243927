import { DocumentStatusEnum, IDocumentAdditonsDeletions, IDocumentEdits, IDocumentUpdate, IFieldChanges } from "@sybl/feature-documents-models";
import { ISyblContext } from "./sybl-context.model";

export interface ISyblContextEntry extends ISyblContext{
  order: number;
}

export interface ISyblContextBasket{
  text: string;
  contexts: [ISyblContextEntry];
  date_time: Date;
  order: number;
  basket_id: string;
  uuid: string;
}



export class SyblContextBasket implements ISyblContextBasket{
  text: string;
  contexts: [ISyblContextEntry];
  date_time: Date;
  order: number;
  basket_id: string;
  uuid: string;

  constructor(params){
    if(params){
      this.text = params.text;
      this.contexts = params.contexts; //
      this.date_time = params.date_time; // Date basket was created.
      this.order = params.order; // Can use order for which ones to default return. Might not use but it is here.
      this.basket_id = params.basket_id;
      this.uuid = params.uuid;
    }
  }
}

export class SyblContextBasketUpdate  extends SyblContextBasket implements IDocumentUpdate {
  master_id?: string;
  flagged?: boolean;
  priority?: number;
  editor_id?: any;
  editor_type: string;
  status?: DocumentStatusEnum;
  wasReversion: boolean;
  additions: IDocumentAdditonsDeletions[];
  deletions: IDocumentAdditonsDeletions[];
  changes: IDocumentEdits[];
  fieldChanges: IFieldChanges[];
  reasonForUpdate: string;


  constructor(params?: any){
    super(params)
    this.text = params.text;
    this.contexts = params.contexts;
    this.date_time = params.date_time;
    this.order = params.order;
    this.basket_id = params.basket_id;
    this.master_id = params.master_id;
    this.flagged = params.flagged;
    this.priority = params.priority;
    this.editor_id = params.editor_id;
    this.uuid = params.uuid;
    this.editor_type = params.editor_type;
    this.status = params.status;
    this.wasReversion = params.wasReversion;
    this.additions = params.additions;
    this.deletions = params.deletions;
    this.changes = params.changes;
    this.fieldChanges = params.fieldChanges;
    this.reasonForUpdate = params.reasonForUpdate;
  }
}
