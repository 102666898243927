import { DocumentStatusEnum, IDocumentAdditonsDeletions, IDocumentEdits, IDocumentUpdate, IFieldChanges } from "@sybl/feature-documents-models";

export interface ISyblContext{
  text: string;
  context_filter: string;
  date_time: Date;
  uuid: string;
}

export class SyblContext implements ISyblContext{
  text: string;
  context_filter: string;
  date_time: Date;
  uuid: string;

  constructor(params){
    if(params){
      this.text = params.text;
      this.context_filter = params.context_filter;
      this.date_time = params.date_time;
      this.uuid = params.uuid;
    }
  }
}

export class SyblContextUpdate  extends SyblContext implements IDocumentUpdate {
  master_id?: string;
  flagged?: boolean;
  priority?: number;
  editor_id?: any;
  editor_type: string;
  status?: DocumentStatusEnum;
  wasReversion: boolean;
  additions: IDocumentAdditonsDeletions[];
  deletions: IDocumentAdditonsDeletions[];
  changes: IDocumentEdits[];
  fieldChanges: IFieldChanges[];
  reasonForUpdate: string;


  constructor(params?: any){
    super(params)
    this.text = params.text;
    this.context_filter = params.context_filter;
    this.date_time = params.date_time;
    this.uuid = params.uuid;
    this.master_id = params.master_id;
    this.flagged = params.flagged;
    this.priority = params.priority;
    this.editor_id = params.editor_id;
    this.editor_type = params.editor_type;
    this.status = params.status;
    this.wasReversion = params.wasReversion;
    this.additions = params.additions;
    this.deletions = params.deletions;
    this.changes = params.changes;
    this.fieldChanges = params.fieldChanges;
    this.reasonForUpdate = params.reasonForUpdate;
  }
}
