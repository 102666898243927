import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient, HttpParams, HttpUrlEncodingCodec, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { UUID } from '@sybl/common-models';
import { ISessionResponse, ISessionStatus } from '@sybl/feature-auth-models';
import { AuthCookieService } from './auth-cookie-service';
import { BrowserInfoService } from './browser-info.service';
import { ITemporaryUser } from '@sybl/feature-sybl-models';

interface AuthEndpoints {
  login: string;
  logout: string;
  signUp: string;
  forgotPassword: string;
  changePassword: string;
  checkPassword: string;
  getUsername: string;
  getEmail: string;
  checkStatus: string;
  tempUser: string;
  teamSignUp: string;
  teamMemberSignUp: string;
  individualSignUp: string;
  createTemporaryUser: string;
}

interface SignUpInfo {
  email: string;
  siteName?: string;
  password?: string;
  firstName: string;
  lastName: string;
  agency_name?: string;
  agency_id?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  productId?: string;
  tenant?: string;
  min_seats?: number;
  licenseType?: any;
  months?: any;
  user_id?: string;
  stripeTestId?: string;
  stripeProdId?: string;
  killBillProdId?: string;
}

@Injectable()
export class AuthBackendService {
  private readonly endpoints: AuthEndpoints;
  private readonly registeredAvatar: string;
  private readonly stripeCheckoutUrl: string;

  constructor(
    @Inject('appUrls') private appUrls: any,
    private http: HttpClient,
    private router: Router,
    private authCookieService: AuthCookieService,
    private location: Location,
    private browserInfoService: BrowserInfoService,
  ) {
    const baseUrl = appUrls.AUTH_URL;
    this.registeredAvatar = appUrls.REGISTERED_AVATAR;

    this.endpoints = {
      login: `${baseUrl}/auth/login`,
      logout: `${baseUrl}/auth/logout`,
      signUp: `${baseUrl}/auth/signUp`,
      forgotPassword: `${baseUrl}/auth/forgot-password`,
      changePassword: `${baseUrl}/auth/change-password`,
      getUsername: `${baseUrl}/auth/getUsername`,
      getEmail: `${baseUrl}/auth/getEmail`,
      checkStatus: `${baseUrl}/auth/checkStatus`,
      tempUser: `${baseUrl}/auth/signUpTemp`,
      checkPassword: `${baseUrl}/auth/check-password`,
      teamSignUp: `${baseUrl}/auth/teamSignUp`,
      teamMemberSignUp: `${baseUrl}/auth/teamMemberSignUp`,
      individualSignUp: `${baseUrl}/auth/individualSignUp`,
      createTemporaryUser: `${baseUrl}/auth/temporary-user`
    };
    this.stripeCheckoutUrl = appUrls.STRIPE_CHECKOUT_URL;
  }

  private handleError(error: any, context: string): Observable<any> {
    console.warn(`Error in ${context}:`, error);
    return of({ error });
  }

  private clearAllCookies(): void {
    this.authCookieService.deleteCookie('auth');
    this.authCookieService.deleteCookie('connect.sid');
    this.authCookieService.deleteCookie('io');
  }

  private handleAuthResponse(response: any): { user?: ISessionStatus; error?: any } {
    if (!response.user) {
      return { error: response.error };
    }

    const user: ISessionStatus = {
      _id: response.user._id,
      user_id: response.user.user_id,
      email: response.user.email,
      tenant: response.user.tenant,
      avatar: response.user.avatar,
      earlyAdopter: response.user.earlyAdopter,
      username: response.user.username,
      jwtToken: response.user.jwtToken,
      refreshToken: response.user.refreshToken,
      expires: response.user.expires,
      sessionId: response.user.sessionId,
      permissions: response.user.permissions,
    };

    return { user };
  }

  checkUsername(username: string): Observable<any> {
    return this.http
      .post(this.endpoints.getUsername, { username }, { withCredentials: true })
      .pipe(
        map(response => response),
        catchError(err => this.handleError(err, 'checkUsername'))
      );
  }

  checkPassword(password: string): Observable<any> {
    return this.http
      .post(this.endpoints.checkPassword, { password }, { withCredentials: true })
      .pipe(
        map(response => response),
        catchError(err => this.handleError(err, 'checkPassword'))
      );
  }

  checkEmail(email: string): Observable<any> {
    return this.http
      .post(this.endpoints.getEmail, { email }, { withCredentials: true })
      .pipe(
        map(response => response),
        catchError(err => this.handleError(err, 'checkEmail'))
      );
  }

  login(email: string, password: string): Observable<any> {
    return this.http
      .post(this.endpoints.login, { email, password }, { withCredentials: true })
      .pipe(
        map(response => this.handleAuthResponse(response)),
        catchError(err => this.handleError(err, 'login'))
      );
  }

  logoutUser(): Observable<any> {
    this.clearAllCookies();
    const currentCookie = this.authCookieService.getCookie('auth');

    if (!currentCookie) {
      return this.http.post(this.endpoints.logout, {}, { withCredentials: true });
    }

    const logoutBody = {
      sessionId: currentCookie['sessionId'],
      user_id: currentCookie['user_id']
    };

    this.router.navigate(['/home']);

    return this.http
      .post(this.endpoints.logout, logoutBody, { withCredentials: true })
      .pipe(
        tap(() => location.reload()),
        catchError(err => this.handleError(err, 'logout'))
      );
  }

  signUp(signUpInfo: SignUpInfo): Observable<any> {
    const body = {
      email: signUpInfo.email,
      siteName: signUpInfo.siteName,
      password: signUpInfo.password,
      firstName: signUpInfo.firstName,
      lastName: signUpInfo.lastName,
      browserInfo: this.browserInfoService.browserInfo(),
      avatar: this.registeredAvatar
    };

    return this.http
      .post(this.endpoints.signUp, body, { withCredentials: true })
      .pipe(
        map((response: any) => {
          if (response.user?.user_id) {
            this.router.navigateByUrl('/co-pilot/chat');
            return response.user;
          }
          return { signUp: 'Fail' };
        }),
        catchError(err => this.handleError(err, 'signUp'))
      );
  }

  checkAuthStatus(): Observable<any> {
    const currentCookie = this.authCookieService.getCookie('auth');

    if (!currentCookie) {
      return this.dumpCookieGetNewSession();
    }

    const params = new HttpParams().set('cookie', currentCookie);

    return this.http
      .post<ISessionResponse>(this.endpoints.checkStatus, params, { withCredentials: true })
      .pipe(
        map(response => response),
        catchError(err => this.handleError(err, 'checkAuthStatus'))
      );
  }

  private dumpCookieGetNewSession(): Observable<any> {
    this.clearAllCookies();

    const username = `guest:${new UUID().generate()}`;
    const email = `${username}@guest.fansay.com`;
    const avatar = 'default.svg';

    return this.http
      .post(this.endpoints.tempUser, { email, username, avatar }, { withCredentials: true })
      .pipe(
        map(response => this.handleAuthResponse(response)),
        catchError(err => this.handleError(err, 'dumpCookieGetNewSession'))
      );
  }

  changePassword(userId: string, password: string, jwToken: string, currentPassword: string): Observable<any> {
    const body = { user_id: userId, password, jwToken, currentPassword };

    return this.http
      .post(this.endpoints.changePassword, body, { withCredentials: true })
      .pipe(
        tap(() => this.clearAllCookies()),
        map(response => this.handleAuthResponse(response)),
        catchError(err => this.handleError(err, 'changePassword'))
      );
  }

  private navigateToCheckout(email: string): void {
    window.location.href = `${this.stripeCheckoutUrl}?prefilled_email=${encodeURI(email)}`;
  }

  createTeamAccount(signUpInfo: SignUpInfo): Observable<any> {
    const body = {
      ...signUpInfo,
      username: signUpInfo.email,
      browserInfo: this.browserInfoService.browserInfo()
    };
    return this.http
      .post(this.endpoints.teamSignUp, body, { withCredentials: true })
      .pipe(
        map((response: any) => {
          if (response.user?.user_id) {
            return response.user;
          }
          return { signUp: 'Fail' };
        }),
        catchError(err => this.handleError(err, 'createTeamAccount'))
      );
  }

  createIndividualAccount(signUpInfo: SignUpInfo): Observable<any> {
    const body = {
      ...signUpInfo,
      username: signUpInfo.email,
      browserInfo: this.browserInfoService.browserInfo()
    };

    return this.http
      .post(this.endpoints.individualSignUp, body, { withCredentials: true })
      .pipe(
        map((response: any) => {
          if (response.user?.user_id) {
            this.navigateToCheckout(signUpInfo.email);
            return response.user;
          }
          return { signUp: 'Fail' };
        }),
        catchError(err => this.handleError(err, 'createIndividualAccount'))
      );
  }

  createTemporaryUser(tempUser: ITemporaryUser): Observable<any> {
    const url = this.endpoints.createTemporaryUser;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(url, tempUser, { headers }).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error) => this.handleError(error, 'createTemporaryUser'))
    );
  }

  /**
   * Check if the current session is valid and not marked for force logout
   * @returns Observable with session validity status
   */
  checkSessionValidity(): Observable<{ valid: boolean; forceLogout?: boolean }> {
    const baseUrl = this.appUrls.AUTH_URL;
    const url = `${baseUrl}/auth/check-session-validity`;

    return this.http.get<{ valid: boolean; forceLogout?: boolean }>(url).pipe(
      catchError((error) => {
        console.error('Error checking session validity:', error);
        // If there's an error, assume the session is invalid
        return of({ valid: false, forceLogout: true });
      })
    );
  }

  setCookieAndNavigateToCheckout(cookieName: string, cookieValue: string, email: string) {
    this.authCookieService.deleteCookie(cookieName);
    this.authCookieService.setCookie(cookieName, cookieValue, 1, '/', '', true, 'Lax');
    this.navigateToCheckout(email);
  }
}
